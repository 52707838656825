<template>
    <Header />    
    <div v-if="post" class="post">
      <h1>{{ post.title.replace("<","").replace("/p>","").replace(">","").replace("\"","") }}</h1>
      <div>
        <span v-for="(category, index) in post.categories" :key="index" class="category">
          {{ category }}<span v-if="index < post.categories.length - 1"></span>
        </span>
      </div>
      <hr>
      <br>
      <div v-html="post.content.replace(new RegExp(post.title.replace(/<[^>]+>/g, '').replace(/'/g, ''), 'g'), '')"></div>
      <div>
        <span v-for="(tag, index) in post.tags" :key="index" class="tag">
          {{ tag }}<span v-if="index < post.tags.length - 1"></span>
        </span>
      </div>
      <hr>
      <!-- Navigation Buttons -->
      <div class="navigation-buttons">
        <router-link v-if="post.id > 1" :to="`/post/${post.id - 1}`" class="nav-button">Previous</router-link>
        <router-link :to="`/post/${post.id + 1}`" class="nav-button-next">Next</router-link>
      </div>
    </div>
    <Footer />
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import postService from '@/services/postService';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'PostView',
  components: {
    Header,
    Footer
  },
  setup() {
    const post = ref(null);
    const route = useRoute();

    const updateMetaTags = (post) => {
      document.title = post.title || 'Post Default Title';
      
      const metaTags = [
        { name: 'title', content: post.title.replace(/<[^>]+>/g, '').replace('"','').substring(0, 200) + (post.title.length > 200 ? '...' : '') || 'Post Default description' },
        { name: 'og:title', content: post.title.replace(/<[^>]+>/g, '').replace('"','').substring(0, 200) + (post.title.length > 200 ? '...' : '') || 'Post Default description' },
        { name: 'description', content: post.content.replace(/<[^>]+>/g, '').substring(0, 800) + (post.content.length > 800 ? '...' : '') || 'Post Default description' },
        { name: 'keywords', content: Array.from(new Set((post.tags + ',' + post.categories).split(',').map(tag => tag.trim()))).join(', ') || 'Post Default keywords' },
        { name: 'og:description', content: post.content.replace(/<[^>]+>/g, '').substring(0, 800) + (post.content.length > 800 ? '...' : '') || 'Post Default description' },
        { name: 'og:keywords', content: Array.from(new Set((post.tags + ',' + post.categories).split(',').map(tag => tag.trim()))).join(', ') || 'Post Default keywords' }
      ];

      metaTags.forEach(({ name, content }) => {
        let meta = document.querySelector(`meta[name="${name}"]`);
        if (!meta) {
          meta = document.createElement('meta');
          meta.name = name;
          document.head.appendChild(meta);
        }
        meta.content = content;
      });
    };

    const fetchPost = async (id) => {
      post.value = await postService.getPost(id);
      if (post.value) {
        updateMetaTags(post.value);
      }
    };

    onMounted(() => {
      const id = route.params.id;
      fetchPost(id);
    });

    // Watch for changes in the route parameters
    watch(() => route.params.id, (newId) => {
      fetchPost(newId);
    });

    return { post };
  },
};
</script>

<style scoped>
.post {
  padding: 30px; /* 增加内边距 */
  max-width: 800px;
  margin: 20px auto; /* 增加外边距 */
  background-color: #f9f9f9; /* 更改背景颜色 */
  border-radius: 12px; /* 增加圆角 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 增加阴影效果 */
}

h1 {
  font-size: 2.5em; /* 增加标题字体大小 */
  margin-bottom: 15px; /* 增加标题下边距 */
  color: #333; /* 更改标题颜色 */
}

p {
  margin: 10px 0; /* 增加段落上下边距 */
  line-height: 1.6; /* 增加行高 */
  color: #555; /* 更改段落颜色 */
}

@media (max-width: 600px) {
  h1 {
    font-size: 2em; /* 在小屏幕上调整标题大小 */
  }
  .post {
    padding: 20px; /* 在小屏幕上调整内边距 */
  }
}

img {
  max-width: 90%; /* 增加图片最大宽度 */
  border-radius: 8px; /* 为图片增加圆角 */
}

.category, .tag {
  display: inline-block; /* Make them inline-block for better layout */
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  padding: 5px 10px; /* Padding for spacing */
  margin: 5px; /* Margin for spacing between items */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}


.category:hover, .tag:hover {
  background-color: #d0d0d0; /* Darker gray on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Styles for navigation buttons */
.navigation-buttons {
  margin-top: 20px; /* Space above the buttons */
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between buttons */
}

.nav-button {
  display: inline-block; /* Make it an inline-block for padding */
  background-color: orange; /* Orange background */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding for the button */
  border-radius: 25px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.nav-button-next {
  display: inline-block; /* Make it an inline-block for padding */
  background-color: green; /* Orange background */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding for the button */
  border-radius: 25px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.nav-button:hover {
  background-color: darkorange; /* Darker orange on hover */
}

.nav-button-next:hover {
  background-color: red; /* Darker orange on hover */
}
</style>