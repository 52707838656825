<template>
  <header class="header">
    <nav>
    <router-link to="/" class="header-title">
      <h1>ChartStudio</h1>
    </router-link>
    <a href="https://apps.apple.com/app/chartstudio-data-analysis/id6474099675" target="_blank">AppStore</a>

    <!---
      <ul>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
      --->
    </nav>
  </header>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Header',
};
</script>

<style scoped>
.header {
  background-color: #f8f9fa; /* Light background color */
  padding: 10px 20px; /* Padding for the header */
  text-align: center; /* Center text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 100%;
}

nav ul {
  text-align: center; /* Center text */
  list-style: none; /* Remove default list styles */
  display: flex; /* Flexbox for horizontal layout */
  gap: 15px; /* Space between items */
}

nav a {
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  color: #333; /* Text color */
}

nav a:hover {
  text-decoration: underline; /* Underline on hover */
  color: orange;
}
</style>