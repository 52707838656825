<template>
  <footer class="footer">
    <p>
      &copy; {{ currentYear }} ChartStudio. All rights reserved.
      <a href='/sitemap.xml' class="footer-link">sitemap</a> 
      <!---
      <router-link to="/privacy" class="footer-link">Privacy Policy</router-link>
      <router-link to="/terms" class="footer-link">Terms of Service</router-link>
      --->
    </p>
    <p>
      <a href="https://www.reddit.com/r/macapps/comments/1ekgumg/chartstudio_visualize_your_data_easily/" target="_blank">Reddit</a>
      <a href="https://zhuanlan.zhihu.com/p/712826861" target="_blank">ZhiHu</a>
      <a href="https://www.producthunt.com/products/chartstudio" target="_blank">ProductHunt</a>
      <a href="https://www.tiktok.com/@emperinter/video/7314043609639095554?q=chartstudio&t=1728060728764" target="_blank">TikTok</a>
      <a href="https://youtu.be/SJ6OHKx4C8U" target="_blank">Youtube</a>     
    </p>
  </footer>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(), // Get the current year
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #343a40; /* Dark background color */
  color: white; /* Text color */
  text-align: center; /* Center text */
   padding: 10px 20px; /* Padding for the footer */
  width: 100%;
}

.footer p {
  margin: 8; /* Remove default margin */
}

.footer a{
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  color: white; /* Text color */
  margin: 0 10px; /* 添加左右间隔 */
}

.footer-link {
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  margin: 0 10px; /* Horizontal spacing between links */
}

.footer-link:hover {
  text-decoration: underline; /* Underline on hover */
}
</style>